import React, { useRef, useState, useEffect, useCallback } from 'react';
import {
    Box,
    Button,
    Chip,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Code, Mic } from 'react-feather';
import { useTransition, animated } from 'react-spring';
import useStyles from '@theme/styles';
import { ArtTrack } from '@material-ui/icons';
import { useRouter } from 'next/router';
import { RESUME_PATH } from '@config/Media';

export default function Home(props: any) {
    const router = useRouter();

    const classes = useStyles();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('xs'));

    const ref = useRef([]);
    const [items, setItems] = useState<string[]>([]);

    const roles = ['code.', 'music.', 'art.'];

    const pushItem = (newItem: string) => {
        const newItems = [...items, newItem];
        setItems(newItems);
    };

    const transitions = useTransition(items, null, {
        from: {
            opacity: 0,
            height: 0,
            innerHeight: 0,
            transform: 'perspective(600px) rotateX(0deg)',
            color: theme.palette.custom.blue,
        },
        enter: [
            { opacity: 1, height: 65, innerHeight: 65 },
            {
                transform: 'perspective(600px) rotateX(180deg)',
                color: theme.palette.custom.orange,
            },
            { transform: 'perspective(600px) rotateX(0deg)' },
        ],
        update: { color: theme.palette.common.white },
    } as any);

    const reset = useCallback(() => {
        ref.current.map(clearTimeout);
        ref.current = [];
        setItems([]);

        let timeDelay = 2000;
        for (let index = 0; index < roles.length; index++) {
            const currRole = roles[index];

            ref.current.push(setTimeout(() => pushItem(currRole), timeDelay) as never);
            timeDelay += 1500;
        }
    }, []);

    useEffect(() => {
        if (!window.localStorage.getItem('visited')) {
            window.localStorage.setItem('visited', 'true');
            reset();
        } else setItems(roles);
    }, []);

    const renderIcon = (label: string) => {
        switch (label) {
            case 'code.':
                return <Code className={classes.skillIcon} />;
            case 'music.':
                return <Mic className={classes.skillIcon} />;
            case 'art.':
                return <ArtTrack fontSize={'large'} />;
            default:
                return null;
        }
    };

    return (
        <Box
            width={'100%'}
            padding={3}
            maxWidth={'750px'}
            flexGrow={1}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'flex-start'}
            alignItems={'center'}
        >
            <style jsx>{`
                svg {
                    stroke-width: 1;
                }
            `}</style>
            <Box
                width={'100%'}
                display={'flex'}
                flexDirection={isMobile ? 'column' : 'row'}
                justifyContent={'flex-start'}
                alignItems={'flex-start'}
                paddingBottom={2}
            >
                <Box
                    minWidth={'250px'}
                    width={isMobile ? '50%' : '40%'}
                    paddingBottom={isMobile && 3}
                >
                    <img
                        style={{
                            width: '100%',
                            borderRadius: '8px',
                        }}
                        src={
                            'https://res.cloudinary.com/jenasnelling/image/upload/v1612328352/Yellow_112020-10_wlpocy.jpg'
                        }
                    />
                </Box>
                <Box paddingX={!isMobile && 3}>
                    <Typography variant={isMobile ? 'h4' : 'h3'}>Nieky Allen</Typography>
                    <Typography
                        variant={'body2'}
                        color={'textSecondary'}
                        style={{ paddingTop: theme.spacing(1) }}
                    >
                        Sometimes I write code. Sometimes I write songs. Other times I
                        like to build things with my hands. Whatever it is, you can check
                        it out here.
                    </Typography>
                    <Grid container spacing={1} style={{ paddingTop: theme.spacing(2) }}>
                        {[
                            'Software Architect',
                            'Writer',
                            'Speaker',
                            'Lyricist',
                            'Audio Engineer',
                            'Mentor',
                        ].map((role) => (
                            <Grid key={role} item>
                                <Chip color={'primary'} label={role} />
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Box>
            <Box width={'100%'} height={'250px'} padding={3}>
                {transitions.map(
                    ({ item, props: { innerHeight, ...rest }, key }: any) => (
                        <animated.div
                            className={classes.transitionItem}
                            key={key}
                            style={rest}
                            onClick={() => router.push(`/${item.replace('.', '')}`)}
                        >
                            <animated.div
                                className={classes.transitionContainer}
                                style={{ overflow: 'hidden', height: innerHeight }}
                            >
                                {item} <Box paddingLeft={1}>{renderIcon(item)}</Box>
                            </animated.div>
                        </animated.div>
                    ),
                )}
            </Box>
            <Box
                width={'100%'}
                height={'100px'}
                display={'flex'}
                flexDirection={'row'}
                justifyContent={'center'}
                alignItems={'center'}
            >
                <Button
                    size={'medium'}
                    variant={'outlined'}
                    color={'secondary'}
                    component={'a'}
                    href={RESUME_PATH}
                    download
                >
                    Resume
                </Button>
            </Box>
        </Box>
    );
}
