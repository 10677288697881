import { Theme, makeStyles } from '@material-ui/core';

const styles = makeStyles((theme: Theme) => ({
    transitionItem: {
        overflow: 'hidden',
        width: '100%',
        padding: theme.spacing(2),
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        fontSize: '3em',
        fontWeight: 800,
        textTransform: 'lowercase',
        willChange: 'transform, opacity, height',
        whiteSpace: 'nowrap',
        lineHeight: '65px',
        borderRadius: '5px',
        '& div div': {
            display: 'none',
        },
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            cursor: 'pointer',
            '& div': {
                color: theme.palette.custom.blue,
            },
            '& div div': {
                display: 'block',
            },
        },
    },
    transitionContainer: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    skillIcon: {
        fontSize: '3em',
    },
    postItem: {
        padding: theme.spacing(2),
        minHeight: '150px',
        borderRadius: '5px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        border: `1px solid ${theme.palette.background.paper}`,
        '&:hover': {
            backgroundColor: theme.palette.background.paper,
            color: theme.palette.custom.orange,
        },
    },
    postDescription: {
        paddingTop: theme.spacing(0.5),
        flex: 1,
    },
    postDate: {
        color: theme.palette.custom.blue,
        alignSelf: 'flex-end',
    },
}));

export default styles;
